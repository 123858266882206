<template>
  <span style="font-size: bold; color: #000;" v-if="!type">{{ value }}</span>
  <span style="font-size: bold; color: #000;" v-else-if="type === 'money'">{{ value | formatAmount }}</span>
</template>

<script>
import _ from 'xe-utils';

export default {
  name: 'crm_form_text_component',
  props: {
    value: {
      type: [String, Number],
      default: '/',
    },
    type: String,
  },
  filters: {
    formatAmount(value) {
      return `￥${_.commafy(_.toNumber(value), { digits: 2 })}`;
    },
  },
};
</script>
