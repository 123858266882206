var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.type
    ? _c("span", { staticStyle: { "font-size": "bold", color: "#000" } }, [
        _vm._v(_vm._s(_vm.value)),
      ])
    : _vm.type === "money"
    ? _c("span", { staticStyle: { "font-size": "bold", color: "#000" } }, [
        _vm._v(_vm._s(_vm._f("formatAmount")(_vm.value))),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }