import BasePage from '@/found/components/table_page';
import request from '@/found/utils/request';
import Form from './form';

export default {
  name: 'request_listening_list',
  extends: BasePage,
  data() {
    return {
      formConfig: {},
    };
  },
  components: {
    Form,
  },
  created() {
    this.getConfigList('request_listening_list');
  },
  methods: {
    // // 按钮状态显隐
    clickVisible({ val, row }) {
      if (val.code === 'repaly' || val === 'divider') {
        const isShow = row.status === 1;

        return isShow;
      }

      return true;
    },
    modalClick({ val, row }) {
      if (val.code === 'getDetail') {
        this.modalConfig.title = '详情';
        this.formConfig = {
          ...row,
        };
        this.openFull();
      } else if (val.code === 'repaly') {
        this.$confirm('确定重新执行该请求？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          request
            .post('/mdm/api/log/redo', {
              id: row.id,
            })
            .then((res) => {
              if (res.success) {
                this.$message({
                  type: 'success',
                  message: `${res.message}成功`,
                });
                this.getList();
              }
            });
        });
      }
    },
  },
};
