import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import FormCode from '../components/code/text.vue';
import FormText from '../components/text';
import configs from './configs';

formCreate.component('FormCode', FormCode);
formCreate.component('FormText', FormText);

export default {
  extends: Form,
  data() {
    return {
      rule: configs.rule,
      buttons: {
        submit: false,
        // close: false,
      },
    };
  },
  components: {
    formCreate: formCreate.$form(),
  },
  created() {
    request.get('/mdm/api/log/findById', { id: this.formConfig.id }).then((res) => {
      if (res.success) {
        this.setValue(res.result);
      }
    });
  },
};
