export default {
  rule: [
    {
      type: 'formHeader',
      title: '',
      props: {
        title: '基本信息',
      },
    },
    {
      type: 'FormText',
      field: 'funCode',
      title: '接口编码',
      col: {
        span: 8,
      },
    },
    {
      type: 'FormText',
      field: 'functionDesc',
      title: '接口名称',
      col: {
        span: 8,
      },
    },
    {
      type: 'FormText',
      field: 'id',
      title: '数据主键',
      col: {
        span: 8,
      },
    },
    {
      type: 'FormText',
      field: 'invokeTime',
      title: '调用时间',
      col: {
        span: 8,
      },
    },
    {
      type: 'FormText',
      field: 'invokeSrc',
      title: '调用系统',
      col: {
        span: 8,
      },
    },
    {
      type: 'FormText',
      field: 'invokeOrg',
      title: '目标系统',
      col: {
        span: 8,
      },
    },
    {
      type: 'FormText',
      field: 'statusName',
      title: '状态',
      col: {
        span: 8,
      },
    },
    {
      type: 'formHeader',
      title: '',
      props: {
        title: '入参',
      },
    },
    {
      type: 'FormCode',
      field: 'params',
      title: '',
    },
    {
      type: 'formHeader',
      title: '',
      props: {
        title: '出参',
      },
    },
    {
      type: 'FormCode',
      field: 'returnMsg',
      title: '',
    },
  ],
};
