<template>
  <pre class="form-code">{{ value }}</pre>
</template>

<script>
import _ from 'xe-utils';

export default {
  name: 'crm_form_code_component',
  props: {
    value: [String, Number],
  },
};
</script>

<style lang="less" scoped>
.form-code {
  width: 100%;
  white-space: pre-wrap;
  word-wrap: break-word;
  min-height: 200px;
  padding: 2px 20px 2px 20px;
  background-color: rgb(0, 0, 0);
  color: rgb(38, 228, 62);
}
</style>
